import { useForm, useFormField } from '@kaliber/forms'
import { required, email } from '@kaliber/forms/validation'
import { useUser } from '/machinery/FirebaseAuthProvider'
import { push, ref, serverTimestamp } from 'firebase/database'
import { useReportError } from '/machinery/ReportError'
import { useMutation } from '@tanstack/react-query'
import { routeMap } from '/routeMap'
import { Button, ButtonLink } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { IconArrowLeft, IconCheckmarkGreen } from '/icons'
import { EMAIL_IS_SENT_KEY, useLocalStorage } from '/machinery/useLocalStorage'

import styles from './SendReportForm.css'
import { useFirebaseDatabase } from '/machinery/useFirebaseDatabase'

export function SendReportForm() {
  const { user } = useUser()
  const [emailIsSent, setEmailIsSent] = useLocalStorage({ key: EMAIL_IS_SENT_KEY, fallbackValue: false })
  const database = useFirebaseDatabase()
  const reportError = useReportError()

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: handleSubmit
  })

  React.useEffect(
    () => {
      if (!emailIsSent && isSuccess)
        setEmailIsSent(true)
    },
    [isSuccess, emailIsSent, setEmailIsSent]
  )

  const { form: { fields }, submit } = useForm({
    initialValues: {
      fullName: '',
      email: '',
      sendToMe: true
    },
    fields: {
      fullName: required,
      email: [required, email],
      sendToMe: null
    },
    onSubmit: mutate,
  })

  if (isSuccess) return <SuccessMessage />

  return (
    <div className={styles.component}>
      <div>
        <p>
          Vul hieronder uw voor- en achternaam in.
        </p>
        <p>
          Zo weet de zorgverlener dat deze samenvatting van u is.
        </p>
      </div>

      <form onSubmit={submit} className={styles.form}>
        {isError && (
          <div className={styles.errorExplanation}>
            <p>
              Er ging iets mis bij het versturen. We proberen het probleem zo snel mogelijk op te lossen.
            </p>
            <p>
              Wilt u het later nog eens proberen?
            </p>
          </div>
        )}
        <TextInput label='Uw voor- en achternaam' required field={fields.fullName} placeholder='bijv. Jan Jansen' />
        <TextInput label='Uw e-mailadres' type='email' required field={fields.email} placeholder='bijv. jan@jansen.nl' />
        <Button type="submit" disabled={isPending} layoutClassName={styles.buttonLayout}>Verstuur mijn samenvatting </Button>
      </form>
    </div>
  )

  async function handleSubmit({ value, invalid }) {
    if (invalid)
      return

    try {
      await push(ref(database, '/services/process-report-service/requests'), {
        formSubmitDate: serverTimestamp(),
        user: {
          uid: user.uid,
        },
        formValues: value
      })
    } catch (e) {
      reportError(e)
      throw e
    }
  }
}

const validationErrors = {
  required: 'Het lijkt erop dat u nog geen voor- en achternaam heeft ingevuld.',
  email: 'Dit e-mail adres lijkt niet te kloppen. Wilt u het nog eens controleren?',
}

function TextInput({ label, field, placeholder, type = 'text', required }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value = '', error, showError } = state

  return (
    <>
      <div className={styles.inputRow}>
        <label htmlFor={name}>{label}</label>
        <input
          id={name}
          className={styles.textInput}
          {...{ name, type, required, value, placeholder }}
          {...eventHandlers}
        />
        {showError && <p className={styles.validationError}>{validationErrors[error.id]}</p>}
      </div>
    </>
  )
}

function SuccessMessage() {
  return (
    <div className={styles.componentSuccessMessage}>
      <div className={styles.successMessage}>
        <Icon icon={IconCheckmarkGreen} layoutClassName={styles.successIconLayout} />
        <div className={styles.successMessageContent}>
          <p>
            Gelukt!
          </p>
          <p>
            Uw samenvatting is verstuurd naar uw e-mail postvak en naar uw zorgverlener.
          </p>
          <p>
            U hoeft de samenvatting niet zelf uit te printen, u ontvangt een papieren kopie op de dag van uw afspraak.
          </p>
        </div>
      </div>
      <ButtonLink
        href={routeMap.app.home({ language: 'nl' })}
        icon={IconArrowLeft}
        iconPosition='left'
        layoutClassName={styles.backButtonLayout}
      >Afronden</ButtonLink>
    </div>
  )
}
