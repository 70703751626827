import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SendReportForm } from './SendReportForm'
import { FirebaseContext } from '/domain/FirebaseContext'

const client = new QueryClient()

export default function SendReportFormApp() {
  return (
    <FirebaseContext>
      <QueryClientProvider {...{ client }}>
        <SendReportForm />
      </QueryClientProvider>
    </FirebaseContext>
  )
}
